import React from "react";


export default function NoPage(): JSX.Element {
    return (
        <div className="page">
            <div className="page">
                <h1>Page Not Found</h1>
            </div>
        </div>
    );
}